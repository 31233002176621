import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { isAxiosError } from "axios";

import { addChat, deleteChat, getChatList } from "../api/requests/requests";
import { Chat } from "../api/types";

import { translation } from "../translations/translation";

import { showNotification } from "./notificationSlice";

interface ChatBotState {
    chatBotList: Chat[];
    loading: boolean;
    currentChat?: Chat;
    error?: string;
}

export const initialState: ChatBotState = {
    chatBotList: [],
    loading: false,
    error: "",
};

export const addBotAsync = createAsyncThunk(
    "bots/addBotAsync",
    async (userData: { name: string; description: string }, { dispatch }) => {
        try {
            await addChat(userData.name, userData.description);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: translation.notifications.addChatBotSuccess,
                }),
            );
            return await getChatList();
        } catch (error) {
            let errorMsg = "Wystąpił błąd, spróbuj ponownie później.";
            if (isAxiosError(error) && error?.response?.data.detail) {
                errorMsg = error.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(error);
            throw error;
        }
    },
);

export const deleteBotAsync = createAsyncThunk("bots/deleteBotAsync", async (chatId: number, { dispatch }) => {
    try {
        const res = await deleteChat(chatId);
        if (res) {
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: translation.notifications.deleteChatbotSuccess,
                }),
            );
        }
        return chatId;
    } catch (error) {
        dispatch(
            showNotification({
                variant: "error",
                title: "Error!",
                subtitle: translation.notifications.deleteChatbotError,
            }),
        );
        console.error("Błąd podczas usuwania dokumentu:", error);
    }
});

export const fetchBotList = createAsyncThunk("bots/fetchBotList", async (_, { dispatch }) => {
    try {
        const response = await getChatList();
        return response;
    } catch (error) {
        let errorMsg = "Wystąpił błąd, spróbuj ponownie później.";
        if (isAxiosError(error) && error?.response?.data.detail) {
            errorMsg = error.response.data.detail;
        }
        dispatch(
            showNotification({
                variant: "error",
                title: "Error!",
                subtitle: errorMsg,
            }),
        );
        console.log(error);
        throw error;
    }
});

const chatsSlice = createSlice({
    name: "bots",
    initialState,
    reducers: {
        clearBots: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addBotAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(addBotAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.chatBotList = action.payload;
            })
            .addCase(addBotAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(deleteBotAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteBotAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.chatBotList = state.chatBotList.filter((chat) => chat.id !== action.payload);
            })
            .addCase(deleteBotAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchBotList.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchBotList.fulfilled, (state, action) => {
                state.loading = false;
                state.chatBotList = action.payload;
            })
            .addCase(fetchBotList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { clearBots } = chatsSlice.actions;
export default chatsSlice.reducer;
