import React, { FC, useEffect } from "react";

import { CircularProgress } from "@mui/material";

import { Layout } from "../../components/Layout/Layout";
import { Navbar } from "../../components/Navbar/Navbar";
import { FirstSection } from "../../components/Sections/FirstSection/FirstSection";
import { SecondSection } from "../../components/Sections/SecondSection/SecondSection";
import { ThirdSection } from "../../components/Sections/ThirdSection/ThirdSection";
import { FourtSection } from "../../components/Sections/FourthSection/FourthSection";
import { FifthSection } from "../../components/Sections/FifthSection/FifthSection";
import { SixthSection } from "../../components/Sections/SixthSection/SixthSection";
import { Footer } from "../../components/Footer/Footer";

export const LandingPage: FC = () => {
    useEffect(() => {
        window.location.assign("https://twoj.chat/");
    }, []);

    return (
        <Layout>
            <CircularProgress />
        </Layout>
    );
};
