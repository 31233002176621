import axios, { AxiosRequestConfig } from "axios";

import { URLS } from "../api/urls";
import { UserDetails } from "../api/types";

interface InternalAxiosRequestConfig extends AxiosRequestConfig {
    headers: {
        Authorization?: string;
    };
}

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASEURL,
});

api.interceptors.request.use(
    async (config: any) => {
        const newConfig: InternalAxiosRequestConfig = { ...config };

        const user = JSON.parse(localStorage.getItem("user") ?? "{}") as UserDetails;

        if (user.access_token && user.access_token !== "undefined") {
            config.headers.Authorization = `Bearer ${user.access_token}`;
        } else {
            return config;
        }

        return newConfig;
    },
    (error) => Promise.reject(error),
);

//@ts-ignore
let refreshingFunc = undefined;

api.interceptors.response.use(
    (response) => {
        return response;
    },

    async (error) => {
        const originalRequest = error.config;

        const user = JSON.parse(localStorage.getItem("user") ?? "{}") as UserDetails;

        if (!user.refresh_token) {
            return Promise.reject(error);
        }
        const isLoginRequest = originalRequest.url.includes("/login");
        try {
            if (
                error.response.status === 401 &&
                //TEMP FIX DUE TO https://pluckyrebels.atlassian.net/jira/software/projects/CE/boards/4?selectedIssue=CE-1010
                error.response.data.detail !== "Użytkownik nie jest aktywny" &&
                //@ts-ignore
                !refreshingFunc &&
                !isLoginRequest
            ) {
                const refreshToken = user.refresh_token;

                refreshingFunc = await axios.post(`${process.env.REACT_APP_API_BASEURL}${URLS.REFRESH_TOKEN}`, {
                    refresh_token: refreshToken,
                });
                const newUserData: UserDetails = {
                    ...user,
                    access_token: refreshingFunc.data.access_token,
                    refresh_token: refreshingFunc.data.refresh_token,
                };
                localStorage.setItem("user", JSON.stringify(newUserData));
                originalRequest.headers["Authorization"] = `Bearer ${refreshingFunc.data.access_token}`;
                return api(originalRequest);
            }
        } catch (error) {
            console.log(error);
            localStorage.removeItem("user");
            throw error;
        } finally {
            refreshingFunc = undefined;
        }

        return Promise.reject(error);
    },
);

export default api;
