import axios from "axios";

import { URLS } from "../urls";
import {
    BasicChat,
    Chat,
    Conversation,
    Document,
    File,
    Message,
    PaymentPlan,
    RatedMessage,
    Register as RegisterData,
    Status,
    Url,
    User,
    UserChat,
    UserDetails,
} from "../types";
import api from "../../store/authService";
import { conversationMapper } from "../mapper";
import { MessageObject } from "../../components/Chat/Chat.types";

export const buyPlan = async (): Promise<any> => {
    return api
        .post(URLS.BUY_PLAN)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const getChatList = async (): Promise<UserChat[]> => {
    return api
        .get(URLS.CHAT)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            throw error;
        });
};

export const addChat = (name: string, description: string): Promise<Chat> => {
    return api
        .post(URLS.CHAT, { name, description })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const editChat = (chatId: number, chatData: BasicChat): Promise<Chat> => {
    return api
        .patch(`${URLS.CHAT}${chatId}/`, {
            name: chatData.name,
            description: chatData.description,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const deleteChat = (chatId: number): Promise<Status> => {
    return api
        .delete(`${URLS.CHAT}${chatId}/`)
        .then((response) => {
            return {
                status: response.status,
            };
        })
        .catch((error) => {
            throw error;
        });
};

export const getPaymentsPlan = (): Promise<PaymentPlan[]> => {
    return api
        .get(URLS.PLANS)
        .then((response) => {
            return response.data.results;
        })
        .catch((error) => {
            throw error;
        });
};

export const extendCurrentPlan = (planId: number): Promise<Url> => {
    return api
        .patch(URLS.PLANS, { payments_plan_id: planId })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const initConversationWithSpecificBot = (idBot: number): Promise<Conversation> => {
    return api
        .post(URLS.CONVERSATIONS, { id: idBot })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const getConversationsWithChat = (chatId: number): Promise<Conversation[]> => {
    return api
        .get(`${URLS.CHAT}${chatId}/`)
        .then((response) => {
            return response.data.result;
        })
        .catch((error) => {
            throw error;
        });
};

export const getOldConversations = async (conversationId: number): Promise<MessageObject[]> =>
    api
        .get(`${URLS.CONVERSATIONS}${conversationId}/messages/`)
        .then((response) => {
            return conversationMapper(response.data.results);
        })
        .catch((error) => {
            throw error;
        });

export const getMessage = async (conversationId: number, messageId: number): Promise<Message> =>
    api
        .get(`${URLS.CONVERSATIONS}${conversationId}/messages/${messageId}/`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const rate = async (conversationId: number, messageId: number, isGood: boolean): Promise<RatedMessage> =>
    api
        .patch(`${URLS.CONVERSATIONS}${conversationId}/messages/${messageId}/`, {
            is_correct_answer: isGood,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const sendQuestion = (
    conversationId: number,
    question: string,
    mode: "context-only" | "whole-knowledge",
): Promise<Message> =>
    api
        .post(
            `${URLS.CONVERSATIONS}${conversationId}/messages/`,
            { question: question, mode: mode },
            { headers: { "Content-Type": "application/json" } },
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const getFiles = async (): Promise<Document[]> => {
    return api
        .get(URLS.DOCUMENTS)
        .then((response) => {
            return response.data.result;
        })
        .catch((error) => {
            throw error;
        });
};

export const addFile = (chatId: number, data: FormData): Promise<File> =>
    api
        .post(`${URLS.DOCUMENTS_ADD}${chatId}`, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const deleteFile = (id: number): Promise<Status> =>
    api
        .delete(`${URLS.DOCUMENTS}${id}`, { headers: { "Content-Type": "multipart/form-data" } })
        .then((response) => {
            return {
                status: response.status,
            };
        })
        .catch((error) => {
            throw error;
        });

export const getUserData = async (): Promise<UserDetails> =>
    api
        .get(URLS.USER_DATA)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const Login = (data: User): Promise<UserDetails> =>
    api
        .post(URLS.LOGIN, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const ConfirmationEmail = (conf_code: string): Promise<Status> =>
    api
        .post(URLS.CONF_EMAIL, { confirmation_code: conf_code })
        .then((response) => {
            return {
                status: response.status,
            };
        })
        .catch((error) => {
            throw error;
        });

export const Register = (data: User): Promise<RegisterData> =>
    axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_BASEURL}${URLS.REGISTER}`,
        data,
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        return res.data;
    });
