import { useDispatch } from "react-redux";

import { clearData } from "../store/authSlice";
import { clearBots } from "../store/botsSlice";
import { clearFiles } from "../store/filesSlice";
import { clearMessages } from "../store/messagesSlice";
import { showNotification } from "../store/notificationSlice";
import { clearConversations } from "../store/conversationsSlice";
import { translation } from "../translations/translation";

export const useLogout = () => {
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(clearData());
        dispatch(clearBots());
        dispatch(clearFiles());
        dispatch(clearMessages());
        dispatch(clearConversations());

        dispatch(
            showNotification({
                variant: "success",
                title: "Sukces!",
                subtitle: translation.notifications.logoutSuccess,
            }),
        );
    };

    return logout;
};
