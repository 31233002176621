import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import { Provider } from "react-redux";

import store from "../src/store/store";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Notifications } from "./components/Notifications/Notifications";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <>
        <Provider store={store}>
            <App />
            <Notifications />
        </Provider>
    </>,
);

reportWebVitals();
