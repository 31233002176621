// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConversationsList_wrapper__p\\+163 {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ConversationsList_list__H-kZL {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ConversationsList_button__QB-SY {
  border: none;
  background-color: transparent;
  height: 40px;
  padding: 0 10px;
  text-align: center;
  align-content: center;
}

.ConversationsList_buttonChecked__i23IF {
  background-color: #F5F7F9;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/ConversationList/ConversationsList.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,uBAAA;EACA,WAAA;AADJ;;AAIA;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,UAAA;EACA,SAAA;AADJ;;AAIA;EACI,YAAA;EACA,6BAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,qBAAA;AADJ;;AAIA;EAEI,yBCPM;EDQN,kBAAA;AADJ","sourcesContent":["@use '../../styles/colors' as colors; \n\n.wrapper {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n}\n\n.list {\n    display: flex;\n    flex-direction: column;\n    list-style-type: none;\n    padding: 0;\n    margin: 0;\n}\n\n.button {\n    border: none;\n    background-color: transparent;\n    height: 40px;\n    padding: 0 10px;\n    text-align: center;\n    align-content: center;\n}\n\n.buttonChecked {\n    composes: button;\n    background-color: colors.$neutral;\n    border-radius: 8px;\n}","$white: #FFFFFF;\n\n$primary: #272E35;\n\n$grey4: #DEE3E7;\n$secondary: #555F6D;\n$whitealfa: #9E9E9E;\n$emerald5: #F4EAD7;\n$emerald7: #F8F6F1;\n\n$green0: #0EB77A;\n$green4: #08200D;\n$green5: #E1F9F0;\n\n// APP\n$secondaryAPP: #D8F8E7;\n$primaryAPP: #1D7C4D;\n$primaryGreenAPP: #086E49;\n$action: #CFD6DD;\n$surface: #FCFCFD;\n$neutral: #F5F7F9;\n$raspberry: #FBD5E2;\n$greenAPP: #C6F1DA;\n$defaultAPP: #EAEDF0;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ConversationsList_wrapper__p+163`,
	"list": `ConversationsList_list__H-kZL`,
	"button": `ConversationsList_button__QB-SY`,
	"buttonChecked": `ConversationsList_buttonChecked__i23IF ConversationsList_button__QB-SY`
};
export default ___CSS_LOADER_EXPORT___;
