import React, { useEffect } from "react";

import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import { Formik, Form, Field, FieldProps } from "formik";

import * as Yup from "yup";

import { useDispatch } from "react-redux";

import { ReactComponent as Logo } from "../../assets/icons/chat.svg";
import { ReactComponent as Gmail } from "../../assets/icons/gmail.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { Button } from "../Button/Button";

import { Register } from "../../api/requests/requests";

import { showNotification } from "../../store/notificationSlice";

import { translation } from "../../translations/translation";

import styles from "./RegisterForm.module.scss";

const validationSchema = Yup.object().shape({
    username: Yup.string().required(translation.schemas.fieldRequired).email(translation.schemas.emaildInvalid),
    password: Yup.string().required(translation.schemas.fieldRequired),
});

export const RegisterForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const handleSubmit = async (values: { username: string; password: string }) => {
        if (!id) return;

        try {
            const res = await Register({
                email: values.username,
                password: values.password,
                payment_plan_id: parseInt(id),
            });
            if (res) {
                dispatch(
                    showNotification({
                        variant: "success",
                        title: "Sukces!",
                        subtitle: translation.notifications.submitRegisterFormSuccess,
                    }),
                );
                navigate("/login");
            }
        } catch (error) {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    //@ts-ignore
                    subtitle: error.response.data.detail,
                }),
            );
            console.log("Błąd logowania:", error);
        }
    };

    useEffect(() => {
        if (!id) {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: translation.notifications.registerFormNoPlanCheckedError,
                }),
            );
            navigate("/");
        }
    }, [id, navigate, dispatch]);

    return (
        <div className={styles.wrapper}>
            <a href="https://twoj.chat/" className={styles.iconTextWrapper}>
                <Logo className={styles.icon} />
                <p className={styles.textWrapper}>{translation.global.appName}</p>
            </a>
            <div className={styles.formContainer}>
                <Formik
                    initialValues={{ username: "", password: "", showPassword: false }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {() => (
                        <Form>
                            <p className={styles.title}>{translation.registerPage.title}</p>
                            <p className={styles.subTitle}>{translation.registerPage.subTitle}</p>
                            <Field name="username">
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <input
                                            type="text"
                                            placeholder={translation.registerPage.firstInputField}
                                            {...field}
                                            className={styles.textField}
                                        />
                                        {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                                    </div>
                                )}
                            </Field>
                            <Field name="password">
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <input
                                            type="password"
                                            placeholder={translation.registerPage.secondInputField}
                                            {...field}
                                            className={styles.textField}
                                        />
                                        {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                                    </div>
                                )}
                            </Field>
                            <div className={styles.registerButtonContainer}>
                                <Button type="submit" variant="secondary" size="medium" fullWidth fullWidthDesktop>
                                    {translation.global.register}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className={styles.mediaButtons}>
                    <Button type="submit" variant="primary" size="medium" disabled fullWidth fullWidthDesktop>
                        <Gmail className={styles.icon} />
                        {translation.global.googleButton}
                    </Button>
                    <Button type="submit" variant="primary" size="medium" disabled fullWidth fullWidthDesktop>
                        <Facebook className={styles.icon} />
                        {translation.global.fbButton}
                    </Button>
                </div>
                <div className={styles.separator}>
                    <div className={styles.lineL} />
                    <p className={styles.separatorText}>{translation.registerPage.haveAccount}</p>
                    <div className={styles.lineP} />
                </div>
                <RouterLink to="/login">
                    <Button variant="primary" size="medium" fullWidth fullWidthDesktop>
                        {translation.global.login}
                    </Button>
                </RouterLink>
            </div>
            <div className={styles.footerContainer}>
                <p className={styles.footerText}>{`2024 © ${translation.global.appName}`}</p>
                <a href="https://twoj.chat/privacy-policy/" className={styles.privatePolContainer}>
                    <p className={styles.footerText2}>{translation.global.privacyPolicy}</p>
                </a>
            </div>
        </div>
    );
};
