import React, { FC } from "react";

import { Layout } from "../../components/Layout/Layout";
import { LoginForm } from "../../components/LoginForm/LoginForm";

export const LoginPage: FC = () => {
    return (
        <Layout>
            <LoginForm />
        </Layout>
    );
};
