import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IconButton, CircularProgress } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useDispatch, useSelector } from "react-redux";

import { Button } from "../Button/Button";
import { showNotification } from "../../store/notificationSlice";
import { addFile } from "../../api/requests/requests";
import { fetchFilesList } from "../../store/filesSlice";
import { AppDispatch, RootState } from "../../store/store";

import { translation } from "../../translations/translation";

import { AddFileFormProps, FormValues } from "./AddFileForm.types";

import styles from "./AddFileForm.module.scss";

const validationSchema = Yup.object().shape({
    document: Yup.mixed().required(translation.schemas.documentRequired),
});

const CustomErrorMessage: React.FC<{ name: string }> = ({ name }) => (
    <ErrorMessage name={name} component="div" className={styles.error} />
);

const AddFileForm: React.FC<AddFileFormProps> = ({ handleModalChange }) => {
    const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
    const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const { chatBotList, loading } = useSelector((state: RootState) => state.bots);

    useEffect(() => {
        if (chatBotList?.length) setSelectedChatId(chatBotList[0].id);
    }, [chatBotList]);

    const handleChatSelect = (chatId: number) => {
        setSelectedChatId(chatId);
    };

    const onSubmit = async (formValues: FormValues, { resetForm }: any) => {
        if (!formValues.document || !selectedChatId) return;

        const formData = new FormData();
        formData.append("file", formValues.document);

        try {
            await addFile(selectedChatId, formData);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: translation.notifications.addDocumentSuccess,
                }),
            );
            dispatch(fetchFilesList());
            handleModalChange();
            resetForm();
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle:
                        //@ts-ignore
                        error.response.data.detail,
                }),
            );
        }
    };

    return (
        <div className={styles.wrapper}>
            <p className={styles.title}>{translation.documentsPage.modal.title}</p>
            <p className={styles.subTitle}>{translation.documentsPage.modal.subTitle}</p>
            <button className={styles.close} onClick={handleModalChange}>
                X
            </button>
            <p className={styles.listTitle}>{translation.documentsPage.modal.listTitle}</p>
            {loading ? (
                <CircularProgress />
            ) : (
                <ul className={styles.chatList}>
                    {chatBotList.map((chat, i) => (
                        <li className={styles.chatListElement} key={chat.id}>
                            <input
                                type="radio"
                                id={`selectedChat${i}`}
                                name="selectedChat"
                                value={chat.id}
                                checked={selectedChatId === chat.id}
                                onChange={() => handleChatSelect(chat.id)}
                            />
                            <label htmlFor={`selectedChat${i}`} className={styles.listElementName}>
                                {chat.name}
                            </label>
                        </li>
                    ))}
                </ul>
            )}
            <Formik
                initialValues={{
                    document: null,
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form className={styles.formContainer}>
                        <p className={styles.inputHeader}>{translation.documentsPage.modal.inputHeader}</p>
                        <div
                            className={styles.uploadContainer}
                            onDragOver={(event) => {
                                event.preventDefault();
                            }}
                            onDrop={(event) => {
                                event.preventDefault();
                                const file = event.dataTransfer.files[0];
                                setFieldValue("document", file);
                                setSelectedFileName(file ? file.name : null);
                            }}
                        >
                            <input
                                type="file"
                                id="document"
                                name="document"
                                data-testid="documentInput"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const file = event.currentTarget.files?.[0];
                                    setFieldValue("document", file);
                                    setSelectedFileName(file ? file.name : null);
                                }}
                                className={styles.fileInput}
                            />
                            <label htmlFor="document" className={styles.uploadButton}>
                                <IconButton component="span">
                                    <CloudUploadIcon fontSize="large" />
                                </IconButton>
                                <span className={styles.wholeSentence}>
                                    {selectedFileName || (
                                        <>
                                            <span className={styles.firstSentence}>
                                                {translation.documentsPage.modal.inputFirstSentence}
                                            </span>
                                            <span>{translation.documentsPage.modal.inputSecondSentence}</span>
                                        </>
                                    )}
                                </span>
                            </label>
                        </div>
                        <CustomErrorMessage name="document" />

                        <div className={styles.submitContainer}>
                            {isSubmitting ? (
                                <CircularProgress data-testid="circularProgress" />
                            ) : (
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    variant="primaryAPP"
                                    fullWidth
                                    fullWidthDesktop
                                    data-testid="addFileForm"
                                >
                                    {translation.documentsPage.modal.submitButton}
                                </Button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AddFileForm;
