// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_wrapper__AJsX2 {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1024px) {
  .Layout_wrapper__AJsX2 {
    max-width: 768px;
    padding: 16px 8px;
  }
}
@media (min-width: 1025px) {
  .Layout_wrapper__AJsX2 {
    max-width: 1440px;
    padding: 32px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.module.scss","webpack://./src/styles/responsive.scss","webpack://./src/styles/globals.scss"],"names":[],"mappings":"AAGA;EACI,iBAAA;EACA,kBAAA;AAFJ;ACDQ;EDCR;IAKQ,gBERK;IFSL,iBAAA;EADN;AACF;ACDQ;EDLR;IAaQ,iBEdW;IFeX,aAAA;EAHN;AACF","sourcesContent":["@use '../../styles/responsive' as rwd; \n@use '../../styles/globals' as settings; \n\n.wrapper {\n    margin-left: auto;\n    margin-right: auto;\n\n    @include rwd.breakpoint(small) {\n        max-width: settings.$page-width;\n        padding: 16px 8px;\n    }\n    @include rwd.breakpoint(medium) {\n        max-width: settings.$page-width-medium;\n        padding: 32px;\n    }\n    @include rwd.breakpoint(large) {\n        max-width: settings.$page-width-large;\n        padding: 32px;\n    }\n}","@mixin breakpoint($point) {\n    @if $point == small {\n        @media (max-width:  1024px) { @content; }\n    }\n    // @else if $point == medium {\n    //     @media (min-width:  769px) and (max-width: 1280px) { @content; }\n    // }\n    @else if $point == large {\n        @media (min-width:  1025px) { @content; }\n    }\n}","$page-width: 768px;\n$page-width-medium: 1440px;\n$page-width-large: 1440px;\n\n@mixin list-reset {\n    list-style: none;\n    margin: 0;\n    padding: 0;\n}\n\n@mixin button-reset {\n    border: none;\n    margin: 0;\n    padding: 0;\n}\n\n\n@mixin button() {\n    @include button-reset;\n\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Layout_wrapper__AJsX2`
};
export default ___CSS_LOADER_EXPORT___;
