import React, { useEffect, useRef, useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import { CircularProgress, Tooltip } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { getMessage, rate, sendQuestion } from "../../api/requests/requests";
import { TypeWriter } from "../TypeWriter/TypeWriter";
import { RootState } from "../../store/store";
import {
    addMessage,
    editLastMessage,
    editMessageById,
    isRequesting as isRequestingAction,
} from "../../store/messagesSlice";
import { showNotification } from "../../store/notificationSlice";

import { translation } from "../../translations/translation";

import styles from "./Chat.module.scss";
import { MessageObject } from "./Chat.types";

export const Chat: React.FC = () => {
    const dispatch = useDispatch();
    const { currentConversation } = useSelector((state: RootState) => state.conversations);
    const { user } = useSelector((state: RootState) => state.auth);

    const { messages, isRequesting, isConversationsLoading } = useSelector((state: RootState) => state.messages);

    useEffect(() => {
        if (messages.length === 0) {
            dispatch(
                addMessage({
                    message: translation.conversationsPage.firstMessage,
                    sentTime: "just now",
                    sender: currentConversation.chat_name,
                    direction: "incoming",
                    position: "normal",
                    mode: currentConversation.mode,
                    canBeRated: false,
                }),
            );
        }
    }, [currentConversation.chat_name, currentConversation.id, dispatch, messages.length]);

    const [value, setValue] = useState<string>("");
    const [typingMessage, setTypingMessage] = useState<string>("");
    const messageListRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const scrollToBottom = () => {
            if (messageListRef.current) {
                messageListRef.current?.scrollIntoView();
            }
        };

        scrollToBottom();
    }, [typingMessage]);

    const checkForAnswer = async (messageId: number) => {
        dispatch(isRequestingAction(true));
        setValue("");
        let init = false;
        const interval = setInterval(async () => {
            try {
                const response = await getMessage(currentConversation.id, messageId);
                if (response.answer_status === 20 || response.answer_status === 25 || response.answer_status === 30) {
                    clearInterval(interval);
                    dispatch(isRequestingAction(false));

                    dispatch(
                        editLastMessage({
                            message: response.answer,
                            sender: currentConversation.chat_name,
                            direction: "incoming",
                            position: "normal",
                            sources: response.source,
                            canBeRated: true,
                            id: response.id,
                        }),
                    );
                }
                if (!init) {
                    dispatch(
                        addMessage({
                            message: response.answer,
                            sender: currentConversation.chat_name,
                            direction: "incoming",
                            position: "normal",
                            sources: response.source,
                            canBeRated: false,
                            mode: response.mode,
                            id: response.id,
                        }),
                    );
                    init = true;
                }
                setTypingMessage(response.answer);
            } catch (error) {
                console.error("Błąd w zapytaniu do API:", error);
            }
        }, 1000);
    };

    const processMessageToAPI = async (chatMessage: MessageObject) => {
        const response = await sendQuestion(currentConversation.id, chatMessage.message, currentConversation.mode);

        checkForAnswer(response.id);
    };

    const handleSend = async (message: string) => {
        const newMessage: MessageObject = {
            message,
            direction: "outgoing",
            position: "normal",
            sender: user.email,
            mode: currentConversation.mode,
            canBeRated: false,
        };

        dispatch(addMessage(newMessage));

        await processMessageToAPI(newMessage);
    };

    const rateAnswer = async (isGood: boolean, messageId: number) => {
        try {
            const res = await rate(currentConversation.id, messageId, isGood);
            if (res) {
                dispatch(
                    editMessageById({
                        messageId: messageId,
                        updatedMessage: { canBeRated: false },
                    }),
                );

                dispatch(
                    showNotification({
                        variant: "success",
                        title: "Sukces!",
                        subtitle: translation.notifications.rateSuccess,
                    }),
                );
            }
        } catch (error) {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: translation.notifications.rateError,
                }),
            );
            console.error("Błąd w ocenie wiadomości:", error);
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapperInner}>
                {isConversationsLoading ? (
                    <CircularProgress />
                ) : (
                    <MainContainer className={styles.mainContainer}>
                        <ChatContainer>
                            <MessageList
                                scrollBehavior="auto"
                                typingIndicator={
                                    isRequesting ? (
                                        <TypingIndicator content={translation.conversationsPage.chatTyping} />
                                    ) : null
                                }
                            >
                                {messages.map((message, i) => {
                                    const isTypingMessage =
                                        messages.length - 1 === i &&
                                        isRequesting &&
                                        message.sender === currentConversation.chat_name;
                                    return (
                                        <Message key={i} model={message} className={styles.messageContentTest}>
                                            <Message.CustomContent className={styles.messageContentTest}>
                                                <div className={styles.headerDetails}>
                                                    <span
                                                        className={
                                                            i % 2 === 0
                                                                ? styles.headerDetailsAvatar1
                                                                : styles.headerDetailsAvatar2
                                                        }
                                                    >
                                                        {message.sender.charAt(0)}
                                                    </span>
                                                    <span>{message.sender}</span>
                                                </div>
                                                {isTypingMessage ? (
                                                    <p ref={messageListRef}>
                                                        <TypeWriter text={typingMessage} />
                                                    </p>
                                                ) : (
                                                    message.message
                                                )}
                                                {message.sources && (
                                                    <ul className={styles.sources}>
                                                        {translation.conversationsPage.sources}:
                                                        {message.sources.map((source, j) => {
                                                            return (
                                                                <Tooltip
                                                                    placement="right"
                                                                    key={`${i}-${j}`}
                                                                    title={source.page_content}
                                                                    style={{ fontSize: 11 }}
                                                                >
                                                                    <li
                                                                        className={styles.singleSource}
                                                                        data-testid="singleSource"
                                                                    >
                                                                        <a
                                                                            target="_blank"
                                                                            href={source.url}
                                                                            rel="noreferrer"
                                                                        >
                                                                            {source.file_source}
                                                                        </a>
                                                                    </li>
                                                                </Tooltip>
                                                            );
                                                        })}
                                                    </ul>
                                                )}
                                                {message.canBeRated && (
                                                    <div className={styles.thumbButtons}>
                                                        <button
                                                            type="button"
                                                            onClick={() => rateAnswer(true, message.id ?? -1)}
                                                            className={styles.thumbUp}
                                                            data-testid="thumbUp"
                                                        >
                                                            👍
                                                        </button>
                                                        <button
                                                            type="button"
                                                            data-testid="thumbDown"
                                                            onClick={() => rateAnswer(false, message.id ?? -1)}
                                                            className={styles.thumbDown}
                                                        >
                                                            👎
                                                        </button>
                                                    </div>
                                                )}
                                            </Message.CustomContent>
                                        </Message>
                                    );
                                })}
                            </MessageList>
                            <MessageInput
                                onChange={(val) => setValue(val)}
                                value={value}
                                attachButton={false}
                                placeholder={translation.conversationsPage.askQuestion}
                                data-testid="askQuestionInput"
                                onPaste={(evt) => {
                                    evt.preventDefault();
                                    document.execCommand("insertText", false, evt.clipboardData.getData("text"));
                                }}
                                className={styles.messageInput}
                                onSend={handleSend}
                            />
                        </ChatContainer>
                    </MainContainer>
                )}
            </div>
        </div>
    );
};
