import React, { FC, useEffect, useState } from "react";

import { CircularProgress, IconButton, Modal } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import DeleteIcon from "@mui/icons-material/Delete";

import AddFileForm from "../../../components/AddFileForm/AddFileForm";
import { AppDispatch, RootState } from "../../../store/store";
import { fetchFilesList } from "../../../store/filesSlice";
import { showNotification } from "../../../store/notificationSlice";

import { Button } from "../../../components/Button/Button";
import { deleteFile } from "../../../api/requests/requests";

import { translation } from "../../../translations/translation";

import { fetchBotList } from "../../../store/botsSlice";

import { DocumentsProps } from "./Documents.types";

import styles from "./Documents.module.scss";

export const Documents: FC<DocumentsProps> = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    useState<boolean>(false);

    const { filesList, loading } = useSelector((state: RootState) => state.files);
    const { chatBotList } = useSelector((state: RootState) => state.bots);

    useEffect(() => {
        dispatch(fetchFilesList());
        if (!chatBotList.length) {
            dispatch(fetchBotList());
        }
    }, [dispatch]);

    const handleModalChange = () => {
        if (chatBotList.length > 0) {
            setModalOpen(true);
        } else {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: translation.notifications.addDocumentError,
                }),
            );
        }
    };

    const handleStatus = (status: number) => {
        if (status === 20) {
            return (
                <div className={styles.badge}>
                    <p className={styles.badgeDot} /> {translation.documentsPage.done}
                </div>
            );
        }
        return (
            <div className={styles.badgeSecondary}>
                <p className={styles.badgeDotSecondary} /> {translation.documentsPage.pending}
            </div>
        );
    };

    const handleDelete = async (id: number) => {
        try {
            const res = await deleteFile(id);
            if (res.status === 204) {
                dispatch(
                    showNotification({
                        variant: "success",
                        title: "Success!",
                        subtitle: translation.notifications.deleteDocumentSuccess,
                    }),
                );
                dispatch(fetchFilesList());
            }
        } catch (error) {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: translation.notifications.deleteDocumentError,
                }),
            );
            console.error("Błąd podczas usuwania dokumentu:", error);
        }
    };

    return (
        <>
            <div className={styles.headerWrapper}>
                <p className={styles.title}>{translation.documentsPage.title}</p>
                <Button variant="primaryAPP" onClick={handleModalChange}>
                    {translation.documentsPage.actionButton}
                </Button>
            </div>
            {loading ? (
                <CircularProgress data-testid="loading-spinner" />
            ) : filesList.length > 0 ? (
                <table className={styles.table}>
                    <thead>
                        <tr className={styles.tableHeader}>
                            <th className={styles.columnHeader}>{translation.documentsPage.table.name}</th>
                            <th className={styles.columnHeader}>{translation.documentsPage.table.added}</th>
                            <th className={styles.columnHeader}>{translation.documentsPage.table.status}</th>
                            <th className={styles.columnHeader}>{translation.documentsPage.table.actions}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filesList.map((doc) => (
                            <tr data-testid="file-row" key={doc.id} className={styles.singleRow}>
                                <td>{doc.file_name}</td>
                                <td>{new Date(doc.created_at).toLocaleString()}</td>
                                <td>{handleStatus(doc.add_to_cdk_status)}</td>
                                <td>
                                    <IconButton edge="end" aria-label="Delete" onClick={() => handleDelete(doc.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>{translation.documentsPage.noFilesMessage}</p>
            )}
            <Modal open={isModalOpen} className={styles.modalWrapper}>
                <AddFileForm handleModalChange={() => setModalOpen(!isModalOpen)} />
            </Modal>
        </>
    );
};
