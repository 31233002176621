import React, { FC, useState } from "react";

import AddIcon from "@mui/icons-material/Add";

import { Modal } from "@mui/material";

import { ChatList } from "../../../components/ChatList/ChatList";

import { Button } from "../../../components/Button/Button";

import { AddNewBotForm } from "../../../components/AddNewBotForm/AddNewBotForm";

import { translation } from "../../../translations/translation";

import styles from "./Chatbots.module.scss";
import { ChatbotsProps } from "./Chatbots.types";

export const Chatbots: FC<ChatbotsProps> = ({ handleTabChange }) => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    return (
        <>
            <div className={styles.headerWrapper}>
                <div>
                    <p className={styles.title}>{translation.chatbotsPage.title}</p>
                    <p className={styles.subTitle}>{translation.chatbotsPage.subTitle}</p>
                </div>
                <Button variant="primaryAPP" onClick={() => setModalOpen(true)}>
                    <AddIcon sx={{ marginLeft: "-12px" }} /> {translation.chatbotsPage.actionButton}
                </Button>
            </div>
            <div className={styles.chatListWrapper}>
                <ChatList handleTabChange={handleTabChange} data-testid="chatListItem" />
            </div>

            <Modal open={isModalOpen} className={styles.modalWrapper} data-testid="modal">
                <AddNewBotForm handleModalChange={() => setModalOpen(false)} />
            </Modal>
        </>
    );
};
