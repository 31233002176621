import { Translations } from "./translation.types";

export const translation: Translations = {
    global: {
        appName: "Twój Chat",
        conversations: "Konwersacje",
        chatbots: "Chatboty",
        database: "Baza wiedzy",
        payment: "Dokonaj płatności",
        extend: "Zwiększ plan",
        hobby: "na plan HOBBY",
        hobbyShortcut: "H",
        enterprise: "na plan ENTERPRISE",
        enterpriseShortcut: "E",
        logout: "Wyloguj się",
        login: "Zaloguj się",
        privacyPolicy: "Polityka prywatności",
        register: "Zarestruj się",
        googleButton: "Kontynuuj z Google",
        fbButton: "Kontynuuj z Facebook",
        plans: {
            free: "Plan FREE",
            freeShortcut: "FREE",
            hobby: "Plan HOBBY",
            hobbyShortcut: "HOBBY",
            enterprise: "Plan ENTERPRISE",
            enterpriseShortcut: "ENTERPRISE",
        },
    },
    schemas: {
        fieldRequired: "Pole jest wymagane",
        emaildInvalid: "Nieprawidłowy format adresu email",
        documentRequired: "Dokument jest wymagany",
    },
    chatbotsPage: {
        title: "Chatboty",
        subTitle: "Tutaj możesz zarządzać chatbotami i wchodzić z nimi w interakcję.",
        actionButton: "Stwórz chatbota",
        modal: {
            title: "Stwórz swojego chatbota",
            subTitle: "i odkryj jego możliwości",
            firstInputField: "Nazwa chata",
            secondInputField: "Opis",
            createBot: "Stwórz chatbota",
            editBot: "Edytuj chatbota",
        },
        table: {
            name: "Nazwa",
            description: "Opis",
            added: "Dodany",
            actions: "Akcje",
        },
        noChatbotsMessage: "Nie masz jeszcze chatów",
    },
    conversationsPage: {
        title: "Konwersacje",
        noChatError: "Przejdź do sekcji Chatboty i wybierz, z którym chcesz rozmawiać",
        conversationsName: "Konwersacja nr.:",
        firstMessage: "Cześć! Jak mogę Ci dzisiaj pomóc?",
        sources: "Źródła",
        askQuestion: "Zadaj pytanie...",
        chatTyping: "Chat pisze...",
    },
    documentsPage: {
        title: "Baza wiedzy",
        actionButton: "Dodaj dokumenty",
        done: "Gotowy",
        pending: "Przetwarzanie",
        table: {
            name: "Nazwa",
            added: "Dodany",
            status: "Status",
            actions: "Akcje",
        },
        modal: {
            title: "Dodaj dokument",
            subTitle: "Prześlij istniejący dokument.",
            listTitle: "Do którego chata chcesz dodać pliki?",
            inputHeader: "Wybierz dokument:",
            inputFirstSentence: "Kliknij, żeby dodać ",
            inputSecondSentence: "lub przerzuć tutaj",
            submitButton: "Dodaj dokument",
        },
        noFilesMessage: "Brak dodanych dokumentów.",
    },
    contactPage: {
        formTitle: "Napisz do nas wiadomość",
        firstInputField: "Twój nick",
        secondInputField: "Twój email",
        textAreaDescription: "Wiadomości",
        submitButton: "Wyślij",
        description:
            "Jesteśmy doświadczonym zespołem specjalistów oferującym innowacyjne, dostosowane do potrzeb klientów rozwiązania, oparte na dostępnych modelach językowych. Doskonale rozumiemy wyzwania, przed którymi stoją polskie firmy. Opowiedz nam o swoich potrzebach, a my kompleksowo zatroszczymy się o Twój projekt, pomagając Ci osiągnąć sukces.",
    },
    registerPage: {
        title: "Zarejestruj się",
        subTitle: "Sugerujemy użyć adresu email, z którego korzystasz w pracy.",
        firstInputField: "Email",
        secondInputField: "Utwórz hasło",
        haveAccount: "Masz już konto?",
    },
    loginPage: {
        title: "Zaloguj się",
        error: "Nieudana próba logowania. Sprawdź nazwę użytkownika i hasło.",
        firstInputField: "Email",
        secondInputField: "Hasło",
        haveAccount: "Nie masz jeszcze konta?",
    },
    notifications: {
        rateSuccess: "Wiadomość została oceniona.",
        rateError: "Wystąpił błąd przy próbie oceny. Spróbuj później.",
        addDocumentSuccess: "Dodawanie pliku zakończone powodzeniem.",
        addDocumentError: "Wystąpił błąd podczas próby dodania pliku. Najpierw dodaj chatbota.",
        deleteDocumentSuccess: "Usuwanie pliku zakończone powodzeniem.",
        deleteDocumentError: "Wystąpił błąd podczas próby usuwania pliku.",
        editChatbotSuccess: "Edytowanie chatbota zakończone powodzeniem.",
        editChatbotError: "Wystąpił błąd podczas próby edytowania chatbota.",
        deleteChatbotSuccess: "Usuwanie chatbota zakończone powodzeniem.",
        deleteChatbotError: "Wystąpił błąd podczas próby usuwania chatbota.",
        submitContactFormSuccess: "Wiadomość została wysłana.",
        submitContactFormError: "Wystąpił błąd, spróbuj ponownie później.",
        submitRegisterFormSuccess: "Email z linkiem potwierdzającym został wysłany. Sprawdź swoją skrzynkę.",
        registerFormNoPlanCheckedError: "Wystąpił błąd, wybierz plan, który Cię interesuje.",
        confirmEmailSuccess: "Email został potwierdzony. Możesz się zalogować.",
        addChatBotSuccess: "Dodawanie chatbota zakończona powodzeniem.",
        addFileSuccess: "Dodawanie pliku zakończone powodzeniem.",
        addFileError: "Wystąpił błąd podczas dodawania pliku. Wymagany format to PDF.",
        fetchConversationsError: "Wystąpił błąd przy próbie pobiernia historii konwersacji.",
        logoutSuccess: "Wylogowano pomyślnie.",
    },
};
