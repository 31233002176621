import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";

import { buyPlan, Login } from "../api/requests/requests";
import { UserDetails } from "../api/types";

import { RootState } from "./store";

interface AuthState {
    user: UserDetails;
    isLoggingIn: boolean;
    error?: SerializedError;
}

export const initialState: AuthState = {
    user: JSON.parse(localStorage.getItem("user") ?? "{}"),
    isLoggingIn: false,
    error: undefined,
};

export const loginAsync = createAsyncThunk("auth/login", async (credentials: { email: string; password: string }) => {
    try {
        const response = await Login(credentials);
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
});

export const buyPlanAsync = createAsyncThunk("auth/buyPlan", async () => {
    try {
        const res = await buyPlan();
        window.location.assign(res.session_url);
        return res;
    } catch (error) {
        console.log(error);
        throw error;
    }
});

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearData: (state) => {
            localStorage.removeItem("user");
            state.user = {} as UserDetails;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.isLoggingIn = true;
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.isLoggingIn = false;
                state.user = action.payload;
                localStorage.setItem("user", JSON.stringify(action.payload));
            })
            .addCase(loginAsync.rejected, (state, action) => {
                state.isLoggingIn = false;
                state.error = action.error;
            })
            .addCase(buyPlanAsync.pending, (state) => {
                state.isLoggingIn = true;
            })
            .addCase(buyPlanAsync.fulfilled, (state, action) => {
                state.isLoggingIn = false;
                state.user = action.payload;
            })
            .addCase(buyPlanAsync.rejected, (state, action) => {
                state.isLoggingIn = false;
                state.error = action.error;
            });
    },
});

export const { clearData } = authSlice.actions;

export const selectAccessToken = (state: RootState) => state.auth.user.access_token;

export const selectRefreshToken = (state: RootState) => state.auth.user.refresh_token;

export default authSlice.reducer;
