import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice";
import idReducer from "./idSlice";
import notificationReducer from "./notificationSlice";
import botsReducer from "./botsSlice";
import filesReducer from "./filesSlice";
import messagesReducer from "./messagesSlice";
import conversationsReducer from "./conversationsSlice";

const store = configureStore({
    reducer: {
        auth: authReducer,
        id: idReducer,
        notification: notificationReducer,
        bots: botsReducer,
        files: filesReducer,
        messages: messagesReducer,
        conversations: conversationsReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
