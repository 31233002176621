import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { isAxiosError } from "axios";

import { addFile, getFiles } from "../api/requests/requests";
import { Document } from "../api/types";

import { translation } from "../translations/translation";

import { showNotification } from "./notificationSlice";

export const addFilesAsync = createAsyncThunk(
    "files/addFileAsync",
    async (values: { chatId: number; data: FormData }, { dispatch }) => {
        try {
            await addFile(values.chatId, values.data);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: translation.notifications.addFileSuccess,
                }),
            );
            return await getFiles();
        } catch (error) {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: translation.notifications.addFileError,
                }),
            );
            throw error;
        }
    },
);

export const fetchFilesList = createAsyncThunk("files/fetchFileList", async (_, { dispatch }) => {
    try {
        const res = await getFiles();
        return res;
    } catch (error) {
        let errorMsg = "Wystąpił błąd, spróbuj ponownie później.";
        if (isAxiosError(error) && error?.response?.data.detail) {
            errorMsg = error.response.data.detail;
        }
        dispatch(
            showNotification({
                variant: "error",
                title: "Error!",
                subtitle: errorMsg,
            }),
        );
        console.log(error);
        throw error;
    }
});

interface FilesState {
    filesList: Document[];
    loading: boolean;
    error?: string;
}

export const initialState: FilesState = {
    filesList: [],
    loading: false,
    error: "",
};

const filesSlice = createSlice({
    name: "files",
    initialState,
    reducers: {
        clearFiles: (state) => {
            state.filesList = [];
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addFilesAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(addFilesAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.filesList = action.payload;
            })
            .addCase(addFilesAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchFilesList.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchFilesList.fulfilled, (state, action) => {
                state.loading = false;
                state.filesList = action.payload;
            })
            .addCase(fetchFilesList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { clearFiles } = filesSlice.actions;
export default filesSlice.reducer;
