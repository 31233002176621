import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Snackbar, Alert } from "@mui/material";

import { hideNotification, selectNotification } from "../../store/notificationSlice";

export const Notifications = () => {
    const notification = useSelector(selectNotification);
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        dispatch(hideNotification());
    }, [dispatch]);

    useEffect(() => {
        if (notification.isVisible) {
            const hideTimer = setTimeout(() => {
                handleClose();
            }, 5000);

            return () => {
                clearTimeout(hideTimer);
            };
        }
    }, [handleClose, notification.isVisible]);

    if (!notification.isVisible) {
        return null;
    }

    return (
        <Snackbar
            open={notification.isVisible}
            autoHideDuration={null}
            onClose={handleClose}
            data-testid="snackbar"
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            sx={{ zIndex: 3000 }}
        >
            <Alert onClose={handleClose} severity={notification.variant}>
                {notification.subtitle}
            </Alert>
        </Snackbar>
    );
};
