import React, { FC } from "react";

import { ButtonProps } from "./Button.types";
import styles from "./Button.module.scss";

export const Button: FC<ButtonProps> = ({
    children,
    size = "normal",
    href,
    disabled,
    variant = "primary",
    type = "button",
    fullWidth,
    fullWidthDesktop,
    ...props
}) => {
    return href ? (
        <a
            className={`${styles[variant]} ${styles[size]} ${fullWidth ? styles.fullWidth : undefined} ${
                fullWidthDesktop ? styles.fullWidthDesktop : undefined
            }`}
            aria-disabled={disabled}
            href={href}
            {...props}
        >
            {children}
        </a>
    ) : (
        <button
            className={`${styles[variant]} ${styles[size]} ${fullWidth ? styles.fullWidth : undefined} ${
                fullWidthDesktop ? styles.fullWidthDesktop : undefined
            }`}
            aria-disabled={disabled}
            disabled={disabled}
            type={type}
            {...props}
        >
            {children}
        </button>
    );
};
