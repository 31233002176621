import React, { FC, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";

import { ConfirmationEmail } from "../../api/requests/requests";
import { Layout } from "../../components/Layout/Layout";
import { showNotification } from "../../store/notificationSlice";
import { translation } from "../../translations/translation";

export const ConfirmEmailPage: FC = () => {
    const { confirmation_code } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!confirmation_code) return;
        const getreq = async () => {
            try {
                const res = await ConfirmationEmail(confirmation_code);
                if (res.status === 200) {
                    dispatch(
                        showNotification({
                            variant: "success",
                            title: "Sukces!",
                            subtitle: translation.notifications.confirmEmailSuccess,
                        }),
                    );
                    navigate("/login");
                }
            } catch (error) {
                dispatch(
                    showNotification({
                        variant: "error",
                        title: "Error!",
                        //@ts-ignore
                        subtitle: error.response.data.detail,
                    }),
                );
                console.log("Błąd logowania:", error);
            }
        };
        getreq();
    }, [confirmation_code, dispatch, navigate]);
    return (
        <Layout>
            <div>
                <CircularProgress data-testid="circular-progress" />
            </div>
        </Layout>
    );
};
