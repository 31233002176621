import React, { useEffect, useState } from "react";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { TypeWriterProps } from "./TypeWriter.types";

export const TypeWriter: React.FC<TypeWriterProps> = ({ text }) => {
    const [displayText, setDisplayText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const interval = Math.floor(999 / text.length);

    useEffect(() => {
        const typingInterval = setInterval(() => {
            if (currentIndex < text.length) {
                setDisplayText((prevText) => prevText + text[currentIndex]);
                setCurrentIndex((prevIndex) => prevIndex + 1);
            } else {
                clearInterval(typingInterval);
            }
        }, interval);

        return () => {
            clearInterval(typingInterval);
        };
    }, [text, interval, currentIndex]);

    return <span>{displayText}</span>;
};
