import { createSlice, createAsyncThunk, SerializedError, PayloadAction } from "@reduxjs/toolkit";

import { getConversationsWithChat, initConversationWithSpecificBot } from "../api/requests/requests";
import { Conversation } from "../api/types";

import { showNotification } from "./notificationSlice";
import { RootState } from "./store";

interface ConversationsState {
    currentConversation: Conversation;
    conversations: Conversation[];
    loading: boolean;
    isInicializingChat: boolean;
    error?: SerializedError;
}

export const initialState: ConversationsState = {
    conversations: [],
    loading: false,
    error: undefined,
    isInicializingChat: false,
    currentConversation: {
        chat_name: "",
        created_at: "",
        id: -1,
        chat_id: -1,
        mode: "context-only",
    },
};

export const fetchConversationsListAsync = createAsyncThunk(
    "conversations/fectchConversations",
    async (idBot: number, { dispatch }) => {
        try {
            const response = await getConversationsWithChat(idBot);
            return response;
        } catch (error) {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    //@ts-ignore
                    subtitle: error.response.data.detail,
                }),
            );
            throw error;
        }
    },
);

export const initConversationWithSpecificBotAsync = createAsyncThunk(
    "conversations/initConversationWithSpecificBotAsync",
    async (idBot: number, { dispatch }) => {
        try {
            const response = await initConversationWithSpecificBot(idBot);
            return response;
        } catch (error) {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    //@ts-ignore
                    subtitle: error.response.data.detail,
                }),
            );
            throw error;
        }
    },
);

const conversationsSlice = createSlice({
    name: "conversations",
    initialState,
    reducers: {
        clearConversations: (state) => {
            state.conversations = initialState.conversations;
            state.currentConversation = initialState.currentConversation;
            state.isInicializingChat = initialState.isInicializingChat;
            state.loading = initialState.loading;
        },
        setCurrentConversation: (state, action: PayloadAction<Conversation>) => {
            state.currentConversation = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchConversationsListAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchConversationsListAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.conversations = action.payload;
            })
            .addCase(fetchConversationsListAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(initConversationWithSpecificBotAsync.pending, (state) => {
                state.isInicializingChat = true;
            })
            .addCase(initConversationWithSpecificBotAsync.fulfilled, (state, action) => {
                const { id, chat_id, created_at, chat_name, mode = "context-only" } = action.payload;
                state.currentConversation = { id, chat_id, created_at, chat_name, mode };
                state.isInicializingChat = false;
            });
    },
});

export const { clearConversations, setCurrentConversation } = conversationsSlice.actions;
export const selectConverastions = (state: RootState) => state.conversations;
export default conversationsSlice.reducer;
