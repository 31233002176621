import { MessageObject } from "../components/Chat/Chat.types";
import { translation } from "../translations/translation";

import { ConversationMessage } from "./types";

export const conversationMapper = (messages: ConversationMessage[]): MessageObject[] => {
    let init = false;
    return messages.flatMap((message) => {
        const { answer, created_at, id, is_correct_answer, question, chat_name, email, source } = message;
        const messages = [
            {
                canBeRated: false,
                direction: "outgoing",
                message: question,
                sentTime: created_at,
                id: `userMessage-${id}`,
                sender: email,
                position: "normal",
            },
            {
                canBeRated: is_correct_answer === null ? true : false,
                direction: "incoming",
                message: answer,
                sentTime: created_at,
                id: id,
                sender: chat_name,
                position: "normal",
                sources: source,
            },
        ];
        if (!init) {
            init = true;
            return [
                {
                    message: translation.conversationsPage.firstMessage,
                    sentTime: "just now",
                    sender: email,
                    direction: "incoming",
                    position: "normal",
                    canBeRated: false,
                },
                ...messages,
            ] as MessageObject[];
        }
        return messages as MessageObject[];
    });
};
