import React, { FC, useEffect, useState } from "react";

import { CircularProgress, IconButton, Modal } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";

import DeleteIcon from "@mui/icons-material/Delete";

import MessageIcon from "@mui/icons-material/Message";

import EditIcon from "@mui/icons-material/Edit";

import { Field, FieldProps, Formik, Form } from "formik";

import { isAxiosError } from "axios";

import { deleteBotAsync, fetchBotList } from "../../store/botsSlice";
import { AppDispatch, RootState } from "../../store/store";
import { clearMessages } from "../../store/messagesSlice";

import { showNotification } from "../../store/notificationSlice";
import { editChat } from "../../api/requests/requests";
import { Chat } from "../../api/types";

import { Button } from "../Button/Button";
import { initConversationWithSpecificBotAsync } from "../../store/conversationsSlice";

import { translation } from "../../translations/translation";

import { ChatListsProps } from "./ChatList.types";
import styles from "./ChatList.module.scss";

export const ChatList: FC<ChatListsProps> = ({ handleTabChange }) => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [currentChat, setCurrentChat] = useState<Chat>();
    const dispatch = useDispatch<AppDispatch>();
    const { chatBotList, loading } = useSelector((state: RootState) => state.bots);

    useEffect(() => {
        dispatch(fetchBotList());
    }, [dispatch]);

    const handleEnter = async (id: number) => {
        try {
            dispatch(clearMessages());
            const res = await dispatch(initConversationWithSpecificBotAsync(id));
            if (res.type === "conversations/initConversationWithSpecificBotAsync/fulfilled") handleTabChange();
        } catch (error) {
            let errorMsg = "Wystąpił błąd, spróbuj ponownie później.";
            if (isAxiosError(error) && error?.response?.data.detail) {
                errorMsg = error.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.error("Błąd przy tworzeniu konwersacji:", error);
        }
    };

    const handleDelete = async (chatId: number) => {
        dispatch(deleteBotAsync(chatId));
    };

    const handleEdit = async (
        values: { name: string; description: string; id: number },
        setSubmitting: (isSubmitting: boolean) => void,
    ) => {
        if (!values) return;
        try {
            const res = await editChat(values.id, {
                description: values.description,
                name: values.name,
            });
            if (res) {
                dispatch(
                    showNotification({
                        variant: "success",
                        title: "Sukces!",
                        subtitle: translation.notifications.editChatbotSuccess,
                    }),
                );
            }
        } catch (error) {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: translation.notifications.editChatbotError,
                }),
            );
            console.error("Błąd podczas edytowania chatbota:", error);
        } finally {
            setSubmitting(false);
            dispatch(fetchBotList());
            setModalOpen(false);
        }
    };

    const handleModalOpen = (chat: Chat) => {
        setCurrentChat(chat);
        setModalOpen(true);
    };

    return (
        <>
            {loading ? (
                <CircularProgress data-testid="circularProgress" />
            ) : chatBotList.length > 0 ? (
                <table className={styles.table}>
                    <thead>
                        <tr className={styles.tableHeader}>
                            <th className={styles.columnHeader}>{translation.chatbotsPage.table.name}</th>
                            <th className={styles.columnHeader}>{translation.chatbotsPage.table.description}</th>
                            <th className={styles.columnHeader}>{translation.chatbotsPage.table.added}</th>
                            <th className={styles.columnHeader}>{translation.chatbotsPage.table.actions}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {chatBotList.map((chat) => (
                            <tr key={chat.id} className={styles.singleRow} data-testid="chat-row">
                                <td>{chat.name}</td>
                                <td>{chat.description}</td>
                                <td>{new Date(chat.created_at).toLocaleString()}</td>
                                <td>
                                    <>
                                        <IconButton
                                            edge="end"
                                            aria-label="Rozpocznij rozmowę"
                                            onClick={() => handleEnter(chat.id)}
                                        >
                                            <MessageIcon />
                                        </IconButton>
                                        <IconButton
                                            edge="end"
                                            aria-label="Edytuj"
                                            onClick={() => handleModalOpen(chat)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton edge="end" aria-label="Usuń" onClick={() => handleDelete(chat.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className={styles.noChats}>{translation.chatbotsPage.noChatbotsMessage}</p>
            )}
            <Modal open={isModalOpen} className={styles.modalWrapper} data-testid="modalWrapper">
                <div className={styles.modalInnerContainer}>
                    <button className={styles.close} onClick={() => setModalOpen(false)}>
                        X
                    </button>
                    <Formik
                        initialValues={currentChat ?? {}}
                        onSubmit={(values, { setSubmitting }) =>
                            // @ts-ignore
                            handleEdit(values, setSubmitting)
                        }
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <p className={styles.title}>{translation.chatbotsPage.modal.title}</p>
                                <p className={styles.subTitle}>{translation.chatbotsPage.modal.subTitle}</p>
                                <Field name="name">
                                    {({ field, meta }: FieldProps) => (
                                        <div>
                                            <input
                                                type="text"
                                                placeholder={translation.chatbotsPage.modal.firstInputField}
                                                {...field}
                                                className={styles.textField}
                                            />
                                            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="description">
                                    {({ field, meta }: FieldProps) => (
                                        <div>
                                            <input
                                                type="text"
                                                placeholder={translation.chatbotsPage.modal.secondInputField}
                                                {...field}
                                                className={styles.textField}
                                            />
                                            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                                        </div>
                                    )}
                                </Field>
                                <div className={styles.createButtonContainer}>
                                    {isSubmitting ? (
                                        <CircularProgress />
                                    ) : (
                                        <Button
                                            type="submit"
                                            variant="secondary"
                                            size="medium"
                                            fullWidth
                                            fullWidthDesktop
                                        >
                                            {translation.chatbotsPage.modal.editBot}
                                        </Button>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </>
    );
};
