// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Chatbots_headerWrapper__A5RM9 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .Chatbots_headerWrapper__A5RM9 {
    flex-direction: column;
  }
}

.Chatbots_routerLink__XCjnk {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.Chatbots_title__rSoF7 {
  font-size: 24px;
}

.Chatbots_subTitle__SR7CF {
  font-size: 16px;
  color: #555F6D;
  margin-top: 4px;
}

.Chatbots_chatListWrapper__lOYs6 {
  margin-top: 24px;
}

.Chatbots_modalWrapper__vOSgj {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/containers/Tabs/Chatbots/Chatbots.module.scss","webpack://./src/styles/responsive.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AAFJ;ACFQ;EDCR;IAMQ,sBAAA;EADN;AACF;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,qBAAA;AADJ;;AAIA;EACI,eAAA;AADJ;;AAIA;EACI,eAAA;EACA,cEpBQ;EFqBR,eAAA;AADJ;;AAIA;EACI,gBAAA;AADJ;;AAIA;EACI,aAAA;EACA,uBAAA;AADJ","sourcesContent":["@use '../../../styles/colors' as colors; \n@use '../../../styles/responsive' as rwd; \n\n.headerWrapper {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    \n    @include rwd.breakpoint(small) {\n        flex-direction: column;\n    }\n}\n\n.routerLink {\n    display: flex;\n    align-items: center;\n    text-decoration: none;\n}\n\n.title {\n    font-size: 24px;\n}\n\n.subTitle {\n    font-size: 16px;\n    color: colors.$secondary;\n    margin-top: 4px;\n}\n\n.chatListWrapper {\n    margin-top: 24px;\n}\n\n.modalWrapper {\n    display: flex;\n    justify-content: center;\n}","@mixin breakpoint($point) {\n    @if $point == small {\n        @media (max-width:  1024px) { @content; }\n    }\n    // @else if $point == medium {\n    //     @media (min-width:  769px) and (max-width: 1280px) { @content; }\n    // }\n    @else if $point == large {\n        @media (min-width:  1025px) { @content; }\n    }\n}","$white: #FFFFFF;\n\n$primary: #272E35;\n\n$grey4: #DEE3E7;\n$secondary: #555F6D;\n$whitealfa: #9E9E9E;\n$emerald5: #F4EAD7;\n$emerald7: #F8F6F1;\n\n$green0: #0EB77A;\n$green4: #08200D;\n$green5: #E1F9F0;\n\n// APP\n$secondaryAPP: #D8F8E7;\n$primaryAPP: #1D7C4D;\n$primaryGreenAPP: #086E49;\n$action: #CFD6DD;\n$surface: #FCFCFD;\n$neutral: #F5F7F9;\n$raspberry: #FBD5E2;\n$greenAPP: #C6F1DA;\n$defaultAPP: #EAEDF0;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerWrapper": `Chatbots_headerWrapper__A5RM9`,
	"routerLink": `Chatbots_routerLink__XCjnk`,
	"title": `Chatbots_title__rSoF7`,
	"subTitle": `Chatbots_subTitle__SR7CF`,
	"chatListWrapper": `Chatbots_chatListWrapper__lOYs6`,
	"modalWrapper": `Chatbots_modalWrapper__vOSgj`
};
export default ___CSS_LOADER_EXPORT___;
