import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IdState {
    id?: number;
}

const initialState: IdState = {
    id: undefined,
};

const idSlice = createSlice({
    name: "id",
    initialState,
    reducers: {
        setId: (state, action: PayloadAction<number>) => {
            state.id = action.payload;
        },
    },
});

export const { setId } = idSlice.actions;
export default idSlice.reducer;
