import React, { FC, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { Chat } from "../../../components/Chat/Chat";

import { ConversationsList } from "../../../components/ConversationList/ConversationsList";

import { translation } from "../../../translations/translation";

import { AppDispatch, RootState } from "../../../store/store";

import { clearConversations, setCurrentConversation } from "../../../store/conversationsSlice";

import styles from "./Conversations.module.scss";
import { ConversationsProps } from "./Conversations.types";

export const Conversations: FC<ConversationsProps> = () => {
    useState<boolean>(false);
    const { chatBotList } = useSelector((state: RootState) => state.bots);
    const { currentConversation } = useSelector((state: RootState) => state.conversations);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (chatBotList?.length === 0) {
            dispatch(clearConversations());
        }
    }, []);

    const handleModeChange = (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        newMode: "context-only" | "whole-knowledge",
    ) => {
        console.log(newMode);
        dispatch(
            setCurrentConversation({
                ...currentConversation,
                mode: newMode,
            }),
        );
        console.log("[opo]");
    };

    return (
        <div className={styles.wrapper}>
            <p className={styles.title}>{translation.conversationsPage.title}</p>
            <div>
                <ToggleButtonGroup
                    value={currentConversation.mode ?? "context-only"}
                    defaultValue={"context-only"}
                    exclusive
                    onChange={handleModeChange}
                    aria-label="contextToggler"
                >
                    <ToggleButton value="context-only">Załączone pliki</ToggleButton>
                    <ToggleButton value="whole-knowledge">Szerszy kontekst</ToggleButton>
                </ToggleButtonGroup>
            </div>
            <div className={styles.wrapperInner}>
                <div className={styles.leftWrapper}>
                    <ConversationsList />
                </div>
                <div className={styles.rightWrapper}>
                    <Chat />
                </div>
            </div>
        </div>
    );
};
