import React, { FC, useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../store/store";
import { fetchConversationsListAsync, setCurrentConversation } from "../../store/conversationsSlice";
import { fetchConversationHistory } from "../../store/messagesSlice";
import { Conversation } from "../../api/types";

import { translation } from "../../translations/translation";

import styles from "./ConversationsList.module.scss";

export const ConversationsList: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    useState<boolean>(false);
    const { currentConversation, conversations, loading } = useSelector((state: RootState) => state.conversations);

    useEffect(() => {
        dispatch(fetchConversationsListAsync(currentConversation.chat_id));
    }, [currentConversation.chat_id, dispatch]);

    useEffect(() => {
        dispatch(fetchConversationHistory(currentConversation.id));
    }, []);

    const reversedConversations = [...conversations]?.reverse();

    const handleConversationChange = async (conversation: Conversation) => {
        await dispatch(fetchConversationHistory(conversation.id));
        dispatch(setCurrentConversation(conversation));
    };

    return (
        <div className={styles.wrapper}>
            {loading ? (
                <CircularProgress />
            ) : (
                <ul className={styles.list}>
                    {reversedConversations?.map((el, i) => {
                        const isChecked = currentConversation.id === el.id;
                        return (
                            <li key={i} className={styles.listItem}>
                                <button
                                    className={isChecked ? styles.buttonChecked : styles.button}
                                    onClick={() => handleConversationChange(el)}
                                >{`${translation.conversationsPage.conversationsName} ${el.id}`}</button>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};
