import React, { FC, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { CircularProgress } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { isAxiosError } from "axios";

import { ReactComponent as ChatSmokes } from "../../assets/icons/chatSmokes.svg";
import { ReactComponent as Robot } from "../../assets/icons/robot.svg";
import { ReactComponent as File } from "../../assets/icons/file.svg";
import { ReactComponent as Logo } from "../../assets/icons/chat.svg";
import { ReactComponent as Wallet } from "../../assets/icons/wallet.svg";
import { ReactComponent as TrendUp } from "../../assets/icons/trend-up.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { Chatbots } from "../../containers/Tabs/Chatbots/Chatbots";
import { ReactComponent as SidebarHide } from "../../assets/icons/sidebar-hide.svg";
import { ReactComponent as SidebarShow } from "../../assets/icons/sidebar-show.svg";
import { Documents } from "../../containers/Tabs/Documents/Documents";
import { buyPlan, extendCurrentPlan, getChatList } from "../../api/requests/requests";

import { Layout } from "../../components/Layout/Layout";
import { AppDispatch, RootState } from "../../store/store";
import { Conversations } from "../../containers/Tabs/Messages/Conversations";
import { setCurrentConversation } from "../../store/conversationsSlice";
import { useLogout } from "../../utils/useLogout";

import { translation } from "../../translations/translation";

import { showNotification } from "../../store/notificationSlice";

import styles from "./ChatPage.module.scss";

const fetchChatList = async () => {
    return await getChatList();
};

export const ChatsPage: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const logout = useLogout();
    const [tabValue, setTabValue] = useState<number>(1);
    const [isPaying, setIsPaying] = useState<boolean>(false);
    const [isSidebarSmall, setSidebarSmall] = useState<boolean>(false);

    const { currentConversation } = useSelector((state: RootState) => state.conversations);
    const { user } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        fetchChatList()
            .then((res) => {
                if (!res[0]) return;
                const { created_at, id, name, conversation_id } = res[0];
                dispatch(
                    setCurrentConversation({
                        chat_id: id,
                        chat_name: name,
                        id: conversation_id,
                        created_at: created_at,
                        mode: "context-only",
                    }),
                );
            })
            .catch((e) => {
                console.error(e);
            });
    }, [dispatch]);

    const handleTabChange = (newValue: number) => {
        setTabValue(newValue);
    };

    const handlePayment = async () => {
        try {
            setIsPaying(true);
            const res = await buyPlan();
            window.location.assign(res.session_url);
        } catch (error) {
            console.log("Błąd logowania:", error);
        } finally {
            setIsPaying(false);
        }
    };

    const handleLogout = () => {
        dispatch(logout);
        navigate("/");
    };

    const handleExtendPlan = async (planId: number) => {
        try {
            const res = await extendCurrentPlan(planId);
            if (res.session_url !== null) {
                window.location.assign(res.session_url);
            } else {
                navigate("/contact");
            }
        } catch (error) {
            let errorMsg = "Wystąpił błąd, spróbuj ponownie później.";
            if (isAxiosError(error) && error?.response?.data.detail) {
                errorMsg = error.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log("Błąd logowania:", error);
        }
    };

    const handleSidebarChange = () => {
        setSidebarSmall(!isSidebarSmall);
    };

    return (
        <Layout>
            <div className={styles.wrapper}>
                <div className={isSidebarSmall ? styles.wrapperLeftSmall : styles.wrapperLeft}>
                    {!isSidebarSmall && (
                        <div className={styles.logoButtonWrapper}>
                            <a href="https://twoj.chat/" className={styles.iconTextWrapper}>
                                <Logo className={styles.icon} />
                                <p className={styles.textWrapper}>{translation.global.appName}</p>
                            </a>
                            <button className={styles.sidebarButton} onClick={() => handleSidebarChange()}>
                                <SidebarHide />
                            </button>
                        </div>
                    )}
                    {isSidebarSmall && (
                        <button className={styles.sidebarButton} onClick={() => handleSidebarChange()}>
                            <SidebarShow />
                        </button>
                    )}
                    <button
                        data-testid="firstTabButton"
                        className={`${isSidebarSmall ? styles.tabButtonSmall : styles.tabButton} ${
                            tabValue === 1 ? styles.checkedTab : undefined
                        }
            `}
                        onClick={() => handleTabChange(1)}
                    >
                        <ChatSmokes className={isSidebarSmall ? styles.tabIconSmall : styles.tabIcon} />
                        {!isSidebarSmall && translation.global.conversations}
                    </button>
                    <button
                        data-testid="secondTabButton"
                        className={`${isSidebarSmall ? styles.tabButtonSmall : styles.tabButton} ${
                            tabValue === 2 ? styles.checkedTab : undefined
                        }
            `}
                        onClick={() => handleTabChange(2)}
                    >
                        <Robot className={isSidebarSmall ? styles.tabIconSmall : styles.tabIcon} />
                        {!isSidebarSmall && translation.global.chatbots}
                    </button>
                    <button
                        data-testid="thirdTabButton"
                        className={`${isSidebarSmall ? styles.tabButtonSmall : styles.tabButton} ${
                            tabValue === 3 ? styles.checkedTab : undefined
                        }
            `}
                        onClick={() => handleTabChange(3)}
                    >
                        <File className={isSidebarSmall ? styles.tabIconSmall : styles.tabIcon} />
                        {!isSidebarSmall && translation.global.database}
                    </button>
                    {!user.plan_is_paid &&
                        user.payment_plan_id !== 1 &&
                        (isPaying ? (
                            <CircularProgress className={styles.circularProgress} />
                        ) : (
                            <button
                                data-testid="paymentButton"
                                className={`${isSidebarSmall ? styles.tabButtonSmall : styles.tabButton} ${
                                    tabValue === 4 ? styles.checkedTab : undefined
                                }
            `}
                                onClick={handlePayment}
                            >
                                <Wallet className={isSidebarSmall ? styles.tabIconSmall : styles.tabIcon} />
                                {!isSidebarSmall && translation.global.payment}
                            </button>
                        ))}
                    {(user.payment_plan_id === 1 || user.payment_plan_id === 2) && (
                        <>
                            <button
                                data-testid="fifthTabButton"
                                className={`${isSidebarSmall ? styles.tabButtonSmall : styles.tabButton} ${
                                    tabValue === 5 ? styles.checkedTab : undefined
                                }
            `}
                                onClick={() => setTabValue(5)}
                            >
                                <TrendUp className={isSidebarSmall ? styles.tabIconSmall : styles.tabIcon} />
                                {!isSidebarSmall && translation.global.extend}
                            </button>
                            {tabValue === 5 && (
                                <div
                                    className={
                                        isSidebarSmall
                                            ? styles.extendButtonsContainerSmall
                                            : styles.extendButtonsContainer
                                    }
                                >
                                    {user.payment_plan_id === 1 && (
                                        <button
                                            className={
                                                isSidebarSmall ? styles.extendPlanButtonSmall : styles.extendPlanButton
                                            }
                                            onClick={() => handleExtendPlan(4)}
                                        >
                                            {isSidebarSmall
                                                ? translation.global.hobbyShortcut
                                                : translation.global.hobby}
                                        </button>
                                    )}
                                    <button
                                        className={
                                            isSidebarSmall ? styles.extendPlanButtonSmall : styles.extendPlanButton
                                        }
                                        onClick={() => handleExtendPlan(3)}
                                    >
                                        {isSidebarSmall
                                            ? translation.global.enterpriseShortcut
                                            : translation.global.enterprise}
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                    <div className={styles.bottomInfoContainer}>
                        <div className={styles.bottomNickContainer}>
                            <div className={styles.avatar}>{user.email[0].toUpperCase()}</div>
                            {!isSidebarSmall && <p className={styles.userName}>{user.email}</p>}
                        </div>
                        <div className={styles.bottomLogoutContainer}>
                            <button
                                className={`${isSidebarSmall ? styles.tabButtonSmall : styles.tabButton}`}
                                onClick={() => handleLogout()}
                            >
                                <Logout className={isSidebarSmall ? styles.tabIconSmall : styles.tabIcon} />
                                {!isSidebarSmall && translation.global.logout}
                            </button>
                        </div>
                        <div className={styles.bottomPlanContainer}>
                            {user.payment_plan_id === 1 && (
                                <p>
                                    {isSidebarSmall
                                        ? translation.global.plans.freeShortcut
                                        : translation.global.plans.free}
                                </p>
                            )}
                            {user.payment_plan_id === 2 && (
                                <p>
                                    {isSidebarSmall
                                        ? translation.global.plans.hobbyShortcut
                                        : translation.global.plans.hobby}
                                </p>
                            )}
                            {user.payment_plan_id === 3 && (
                                <p>
                                    {isSidebarSmall
                                        ? translation.global.plans.enterpriseShortcut
                                        : translation.global.plans.enterprise}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className={tabValue === 1 ? styles.wrapperRightChat : styles.wrapperRight}>
                    {tabValue === 1 &&
                        (currentConversation.id !== -1 && currentConversation.id !== null ? (
                            <Conversations />
                        ) : (
                            <p className={styles.noChatError}>{translation.conversationsPage.noChatError}</p>
                        ))}
                    {tabValue === 2 && <Chatbots handleTabChange={() => handleTabChange(1)} />}
                    {tabValue === 3 && <Documents />}
                </div>
            </div>
        </Layout>
    );
};
