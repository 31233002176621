import React, { FC } from "react";

import { createMemoryHistory } from "history";

import { Router } from "react-router-dom";

import { Layout } from "../../components/Layout/Layout";
import { CreateChatForm } from "../../components/CreateChatForm/CreateChatForm";

export const AddChatPage: FC = () => {
    const history = createMemoryHistory();
    return (
        <Layout>
            <Router location={"/"} navigator={history}>
                <CreateChatForm />
            </Router>
        </Layout>
    );
};
