import React, { FC } from "react";

import { Formik, Form, Field, FieldProps } from "formik";

import * as Yup from "yup";

import { useDispatch } from "react-redux";

import { Button } from "../Button/Button";
import { addBotAsync } from "../../store/botsSlice";
import { AppDispatch } from "../../store/store";

import { translation } from "../../translations/translation";

import { AddNewBotFormProps } from "./AddNewBotForm.types";

import styles from "./AddNewBotForm.module.scss";

const validationSchema = Yup.object().shape({
    name: Yup.string().required(translation.schemas.fieldRequired),
    description: Yup.string().required(translation.schemas.fieldRequired),
});

export const AddNewBotForm: FC<AddNewBotFormProps> = ({ handleModalChange }) => {
    const dispatch = useDispatch<AppDispatch>();

    const handleSubmit = (values: { name: string; description: string }) => {
        handleModalChange();
        return dispatch(addBotAsync(values));
    };

    return (
        <div className={styles.formContainer}>
            <button className={styles.close} onClick={handleModalChange}>
                X
            </button>
            <Formik
                initialValues={{ name: "", description: "" }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {() => (
                    <Form>
                        <p className={styles.title}>{translation.chatbotsPage.modal.title}</p>
                        <p className={styles.subTitle}>{translation.chatbotsPage.modal.subTitle}</p>
                        <Field name="name">
                            {({ field, meta }: FieldProps) => (
                                <div>
                                    <input
                                        type="text"
                                        placeholder={translation.chatbotsPage.modal.firstInputField}
                                        {...field}
                                        className={styles.textField}
                                    />
                                    {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                                </div>
                            )}
                        </Field>
                        <Field name="description">
                            {({ field, meta }: FieldProps) => (
                                <div>
                                    <input
                                        type="text"
                                        placeholder={translation.chatbotsPage.modal.secondInputField}
                                        {...field}
                                        className={styles.textField}
                                    />
                                    {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                                </div>
                            )}
                        </Field>
                        <div className={styles.createButtonContainer}>
                            <Button type="submit" variant="primaryAPP" size="medium" fullWidth fullWidthDesktop>
                                {translation.chatbotsPage.modal.createBot}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
