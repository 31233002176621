export enum URLS {
    MESSAGES = `/messages/`,
    DOCUMENTS = `/documents/`,
    DOCUMENTS_ADD = `/documents/chat/`,
    CONVERSATIONS = `/conversations/`,
    CHAT = `/conversations/chat/`,
    PLANS = `/payments/plans/`,
    BUY_PLAN = `/payments/session/`,
    LOGIN = `/authorization/login/`,
    CONF_EMAIL = `/authorization/confirm_email/`,
    REGISTER = `/authorization/register/`,
    REFRESH_TOKEN = `/authorization/token/refresh/`,
    USER_DATA = `/authorization/user/`,
}
