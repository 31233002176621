// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Conversations_wrapper__ANizi {
  height: 100%;
}

.Conversations_title__XIQia {
  font-size: 24px;
  padding-bottom: 24px;
}
@media (max-width: 1024px) {
  .Conversations_title__XIQia {
    padding-bottom: 8px;
  }
}

.Conversations_wrapperInner__9vtOW {
  display: flex;
  flex-direction: row;
  height: 100%;
  border-radius: 8px;
  background-color: #FFFFFF;
  padding: 24px;
  max-height: -webkit-fill-available;
}

.Conversations_leftWrapper__U-VK0 {
  width: 220px;
  border-right: 1px solid #CFD6DD;
  padding-right: 20px;
  overflow: auto;
}
@media (max-width: 1024px) {
  .Conversations_leftWrapper__U-VK0 {
    display: none;
  }
}

.Conversations_rightWrapper__-OrkA {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/containers/Tabs/Messages/Conversations.module.scss","webpack://./src/styles/responsive.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACI,YAAA;AAFJ;;AAKA;EACI,eAAA;EACA,oBAAA;AAFJ;ACLQ;EDKR;IAKQ,mBAAA;EADN;AACF;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,yBErBI;EFsBJ,aAAA;EACA,kCAAA;AADJ;;AAIA;EACI,YAAA;EACA,+BAAA;EACA,mBAAA;EACA,cAAA;AADJ;AC3BQ;EDwBR;IAOQ,aAAA;EAAN;AACF;;AAGA;EACI,WAAA;AAAJ","sourcesContent":["@use '../../../styles/colors' as colors; \n@use '../../../styles/responsive' as rwd;\n\n.wrapper {\n    height: 100%;\n}\n\n.title {\n    font-size: 24px;\n    padding-bottom: 24px;\n\n    @include rwd.breakpoint(small) {\n        padding-bottom: 8px;\n    }\n}\n\n.wrapperInner {\n    display: flex;\n    flex-direction: row;\n    height: 100%;\n    border-radius: 8px;\n    background-color: colors.$white;\n    padding: 24px;\n    max-height: -webkit-fill-available; //TODO\n}\n\n.leftWrapper {\n    width: 220px;\n    border-right: 1px solid colors.$action;\n    padding-right: 20px;\n    overflow: auto;\n    \n    @include rwd.breakpoint(small) {\n        display: none;\n    }\n}\n\n.rightWrapper {\n    width: 100%;\n}","@mixin breakpoint($point) {\n    @if $point == small {\n        @media (max-width:  1024px) { @content; }\n    }\n    // @else if $point == medium {\n    //     @media (min-width:  769px) and (max-width: 1280px) { @content; }\n    // }\n    @else if $point == large {\n        @media (min-width:  1025px) { @content; }\n    }\n}","$white: #FFFFFF;\n\n$primary: #272E35;\n\n$grey4: #DEE3E7;\n$secondary: #555F6D;\n$whitealfa: #9E9E9E;\n$emerald5: #F4EAD7;\n$emerald7: #F8F6F1;\n\n$green0: #0EB77A;\n$green4: #08200D;\n$green5: #E1F9F0;\n\n// APP\n$secondaryAPP: #D8F8E7;\n$primaryAPP: #1D7C4D;\n$primaryGreenAPP: #086E49;\n$action: #CFD6DD;\n$surface: #FCFCFD;\n$neutral: #F5F7F9;\n$raspberry: #FBD5E2;\n$greenAPP: #C6F1DA;\n$defaultAPP: #EAEDF0;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Conversations_wrapper__ANizi`,
	"title": `Conversations_title__XIQia`,
	"wrapperInner": `Conversations_wrapperInner__9vtOW`,
	"leftWrapper": `Conversations_leftWrapper__U-VK0`,
	"rightWrapper": `Conversations_rightWrapper__-OrkA`
};
export default ___CSS_LOADER_EXPORT___;
